import React, { useState } from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'

// Images
import Logo from 'img/logo.inline.svg'
import Phone from 'img/phone-icon.inline.svg'
import Mail from 'img/mail.inline.svg'

// Components
import CustomLink from 'components/shared/CustomLink'
import Image from 'components/shared/Image'

const StyledHeader = styled.nav`
  background-color: rgba(26,29,36,0.75);
  position: fixed;
  left: 0;
  top: 37px;
  right: 0;
  height: 80px;
  z-index: 3;
`

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
`

const Brand = styled(CustomLink)`
  & > svg {
    height: 70px;
    width: 197px;
  }
`

const NavButton = styled.button`
  width: 80px;
  height: 50px;
  padding: 0 10px;
  text-align: center;
  z-index: 10;
`

const NavButtonTitle = styled.div`
  color: ${props => props.theme.color.text.light};
  text-transform: uppercase;
`

const NavButtonStripe = styled.div`
  background-color: ${props => props.theme.color.face.light};
  height: 2px;
  width: 70%;
  margin: 5px auto;
`

const TopBar = styled.div`
  background-color: ${props => props.theme.color.face.main};
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 3;

  & a {
    font-size: ${props => props.theme.font.size.sm};
  }
`

const PhoneIcon = styled(Phone)`
  height: 15px;
  margin-right: 5px;
`

const EmailIcon = styled(Mail)`
  height: 15px;
  margin-right: 8px;
`

const SocialImage = styled(Image)`
  height: 15px;
  margin-right: 5px;
`

const Header = ({ setIsScrollable }) => {
  const {
    fields
  } = useStaticQuery(graphql`
    {
      fields: wordpressWpComponenten(wordpress_id: {eq: 97}) {
        acf {
          topbar {
            email
            phone {
              title
              url
            }
            social {
              item {
                icoon {
                  localFile {
                    publicURL
                  }
                }
                link {
                  target
                  url
                }
              }
            }
          }
          menu {
            link {
              title
              url
            }
          }
        }
      }
    }
  `)

return (
    <>
    <TopBar>
      <div className="container">
        <div className="row py-2">
          <div className="col-11 col-md-6 pr-0 pr-md-3 d-flex flex-wrap">
            <a href={fields.acf.topbar.phone.url} className="mr-3 mr-lg-4 d-flex align-items-center">
              <PhoneIcon />
              <span>{fields.acf.topbar.phone.title}</span>
            </a>
            <a href={`mailto:${fields.acf.topbar.email}`} className="d-flex align-items-center">
              <EmailIcon />
              <span>{fields.acf.topbar.email}</span>
            </a>
          </div>
          <div className="col-1 pl-0 pl-md-3 col-md-6 d-flex flex-wrap justify-content-end">
            {fields.acf.topbar.social.map(({ item }, index) => (
              <a href={item.link.url} target={item.link.target}>
                <SocialImage src={item.icoon} />
              </a>
            ))}
          </div>
        </div>
      </div>
    </TopBar>
    <StyledHeader>
      <Container className="container">
        <Brand to="/">
          <Logo />
        </Brand>

        <MenuMobile fields={fields} setIsScrollable={setIsScrollable} />
        <MenuDesktop fields={fields} />
      </Container>
    </StyledHeader>
    </>
  )
}

const MenuWrapper = styled.div`
  background-color: ${props => props.theme.color.face.main};
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99;
  padding: 30px 0 0;

  & > ul {
    padding: 50px 0 0;
    list-style-type: none;
    text-align: center;
    width: 60%;
    margin: 0 auto;

    & li {
      padding: 0;

      & a {
        font-size: ${props => props.theme.font.size.l};
        border-bottom: 1px solid #f1f1f1;
        display: block;
        padding: 10px 0;
      }

      &:last-child a {
        border-bottom: none;
      }
    }
  }
`

const NavButtonClose = styled.div`
  position: absolute;
  right: 20px;
  top: 20px;
  text-align: center;
`

const NavButtonCloseCross = styled.div`
  position: relative;
  width: 32px;
  height: 32px;
  margin-left: 16px;

  &:before, &:after {
    background-color: ${props => props.theme.color.text.light};
    position: absolute;
    content: ' ';
    height: 33px;
    width: 2px;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
`

const NavButtonCloseTitle = styled.div`
  color: ${props => props.theme.color.text.light};
  text-transform: uppercase;
  margin-top: 3px;
`

const MenuMobile = ({ fields, setIsScrollable }) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div className="d-block d-lg-none">
      <NavButton
        role="button"
        onClick={() => {
          setIsOpen(!isOpen)
          setIsScrollable(false)
        }}
      >
        <NavButtonStripe />
        <NavButtonStripe />
        <NavButtonStripe />
        <NavButtonTitle>
          Menu
        </NavButtonTitle>
      </NavButton>
      {isOpen && (
        <MenuWrapper>
          <NavButtonClose
            role="button"
            onClick={() => {
              setIsOpen(!isOpen)
              setIsScrollable(true)
            }}
          >
            <NavButtonCloseCross />
            <NavButtonCloseTitle>
              Sluiten
            </NavButtonCloseTitle>
          </NavButtonClose>
          <ul>
            <Menu fields={fields} />
          </ul>
        </MenuWrapper>
      )}
    </div>
  )
}

const StyledMenuDesktop = styled.ul`
  flex: 1;
  align-items: center;
  justify-content: space-around;
  list-style: none;
  margin-bottom: 0;
`

const MenuDesktop = ({ fields }) => (
  <StyledMenuDesktop className="d-none d-lg-flex">
    <Menu fields={fields} />
  </StyledMenuDesktop>
)

const Item = styled.li`
  & > a {
    color: ${props => props.theme.color.text.light} !important;

    &[aria-current] {

    }

    &:hover {

    }
  }
`

const Menu = ({ fields }) => (
  <>
    {fields.acf.menu.map(({ link: { url, title } }, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <Item key={index}>
        <CustomLink to={url}>{title}</CustomLink>
      </Item>
    ))}
  </>
)

export default Header
