import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled from 'styled-components'
import Content from 'components/shared/Content'
import ButtonDefault from 'components/elements/ButtonDefault'

// Images
import Logo from 'img/logo.inline.svg'
import Mail from 'img/mail.inline.svg'
import Phone from 'img/phone-icon.inline.svg'
import Location from 'img/location.inline.svg'

const CTAFooter = styled.div``

const StyledFooter = styled.div`
  background-color: ${props => props.theme.color.face.secondary};
  color: ${props => props.theme.color.text.light};

  & h2 {
    font-size: ${props => props.theme.font.size.xm};
  }
`

const CTATitle = styled(Content)`
  & h2 {
    color: ${props => props.theme.color.text.light};
    font-size: ${props => props.theme.font.size['40']};
    font-weight: ${props => props.theme.font.weight['700']};

    @media (max-width: 991px){
      font-size: ${props => props.theme.font.size.l};
    }
  }
`

const Button = styled(ButtonDefault)`
  padding: 10px 15px;
  font-size: 18px;
`

const UL = styled.ul`
  list-style: none;
  margin-left: -2.5rem;
`

const FooterLogo = styled(Logo)`
  width: 175px;
`

const PhoneIcon = styled(Phone)`
  width: 15px;
  margin-right: 15px;
`

const MailIcon = styled(Mail)`
  width: 15px;
  margin-right: 15px;
`

const LocationIcon = styled(Location)`
  width: 15px;
  margin-right: 15px;
`

const Footer = ({ noCta }) => {
  const {
    fields
  } = useStaticQuery(graphql`
    {
      fields: wordpressWpComponenten(wordpress_id: {eq: 98}) {
        acf {
          info {
            description
            location
            phone {
              url
              title
            }
            email
          }
          cta {
            title
            button {
              title
              url
            }
          }
          menu {
            link {
              title
              url
            }
          }
          menuServices: menu_services {
            link {
              title
              url
            }
          }
        }
      }
    }
  `)

  return (
    <>
      {!noCta && (
        <CTAFooter className="color-background-main">
          <div className="container py-lg-4">
            <div className="row py-3 py-lg-5 align-items-center">
              <div className="col-lg-8 mb-4 mb-lg-0">
                <CTATitle content={fields.acf.cta.title} />
              </div>
              <div className="col-lg-4 d-flex justify-content-lg-end">
                <Button to={fields.acf.cta.button.url}>{fields.acf.cta.button.title}</Button>
              </div>
            </div>
          </div>
        </CTAFooter>
      )}
      <StyledFooter>
        <div className="container">
          <div className="row py-5">
            <div className="col-lg-5 mb-4 mb-lg-0">
             <FooterLogo />
             <Content content={fields.acf.info.description} className="mt-3 mb-4 font-size-sm pr-lg-5" />
             <div className="d-flex align-items-start">
               <LocationIcon />
               <Content content={fields.acf.info.location} />
             </div>
             <a href={fields.acf.info.phone.url} className="d-flex align-items-center mb-2">
               <PhoneIcon />
               <span>{fields.acf.info.phone.title}</span>
             </a>
             <a href={`mailto:${fields.acf.info.email}`} className="d-flex align-items-center">
               <MailIcon />
               <span>{fields.acf.info.email}</span>
             </a>
            </div>
            <div className="col-lg-3 mb-4 mb-lg-0">
              <h2 className="mb-3 mb-lg-4">Menu</h2>
              <UL>
                {fields.acf.menu.map(({ link: { url, title } }, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <li key={index}>
                    <Link to={url}>
                      {title}
                    </Link>
                  </li>
                ))}
              </UL>
            </div>
            <div className="col-lg-4 mb-0">
              <h2 className="mb-3 mb-lg-4">Diensten</h2>
              <UL>
                {fields.acf.menuServices.map(({ link: { url, title } }, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <li key={index}>
                    <Link to={url}>
                      {title}
                    </Link>
                  </li>
                ))}
              </UL>
            </div>
           
          </div>
        </div>
      </StyledFooter>
    </>
  )
}

export default Footer
