import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

// Libraries
import CookieConsent from 'react-cookie-consent'

// Theme
import Theme from 'styles/Theme'

// Components
import Footer from './Footer'
import Header from './Header'

// CSS
import 'styles/all.scss'

const Content = styled.div`
  padding-top: 0;
`

const CookieLink = styled(Link)`
  color: ${props => props.theme.color.text.light};
  text-decoration: underline;
`

const Layout = ({ children, noCta }) => {
  const [isSticky, setSticky] = useState(false)
  const [isScrollable, setIsScrollable] = useState(true)

  const handleScroll = () => {
    setSticky(window.scrollY > 0)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', () => handleScroll)
    }
  }, [handleScroll])

  return (
    <Theme isScrollable={isScrollable}>
      <Header isSticky={isSticky} setIsScrollable={setIsScrollable} />
      <Content isSticky={isSticky}>{children}</Content>
      <Footer noCta={noCta} />
    </Theme>
  )
}

export default Layout
