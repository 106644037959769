import React from 'react'
import styled from 'styled-components'
import ButtonShell from './ButtonShell'

const StyledButton = styled.div`
  background-color: ${props => props.theme.color.face.contrast};
  display: inline-block;
  
  & > a, & > button {
    color: ${props => props.theme.color.text.light};
    padding: 10px 15px;
    width: 100%;
    height: 100%;
    display: block;
  }
`

const ButtonDefault = ({ isAnchor, isCustom, to, children, className }) => (
  <StyledButton className={className}>
    {isCustom ? children : (
      <ButtonShell to={to} isAnchor={isAnchor}>
        {children}
      </ButtonShell>
    )}
  </StyledButton>
)

export default ButtonDefault