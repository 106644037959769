import styled, { css } from 'styled-components'


const TitleDefault = styled.h1`
  font-size: ${props => props.theme.font.size.xl};
  font-weight: ${props => props.theme.font.weight.l};
  font-family: ${props => props.theme.font.family.secondary};

  ${props => props.withStripe && css`
  & height: 1px; 

     &:after {
      content: '';
      width: 37px;
      height: 3px;
      display: block;
      margin-top: 17px;
      background-color: ${props => props.theme.color.face.contrast};
    }
 `}
`

export default TitleDefault